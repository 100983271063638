<template>
    <div>
        <WebRTC :storeId="activeStoreId()"/>        
    </div>
</template>

<script>
import WebRTC from '../../components/app/WebRTC.vue'

export default {

    components: {
        WebRTC
    },

    mounted() {
        //  console.log("Web RTC Page")
    },
    
}

</script>