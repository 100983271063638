<template>
    <div class="box">
        <div class="box">
            <b-button v-if="isScreenSharing" @click="stopScreenShare">Stop Screen Share</b-button>
            <b-button v-if="wsConnected && !isScreenSharing" @click="startScreenShare">Share My Screen</b-button>
            <b-button v-if="wsConnected && !isScreenSharing" @click="disconnectWS">Disconnect</b-button>
            <b-button v-if="!isScreenSharing && !wsConnected" @click="connectWS">Connect</b-button>
            &nbsp;&nbsp;<b-checkbox v-show="isScreenSharing" v-model="showSharedScreen">Show Shared Screen</b-checkbox>
            {{ wsMessage.room }}
        </div>
        
        <video id="sharedUserScreen" v-show="isScreenSharing && showSharedScreen" style="width: 500px; height: 400px;" autoplay playsinline controls></video>
        
    </div>

</template>

<script>

import UserStore from '../../store/UserStore.js'

export default {
    data() {
        return {
            showLocalCamera: true,
            showRemoteCamera: true,
            isScreenSharing: true,
            showSharedScreen: true,
            wsConnected: false,
            wsConn: null,
            wsMessage: {"type": "create", 
						"room": null,
						"sdp": {},
						"name": UserStore.getters.getUserName,
						"email": UserStore.getters.getUserEmail,
						"phone": UserStore.getters.getUserPhone },
            shareTracks: [],
            iceServers: [
                {
                    urls: 'turn:kumarakam.metered.live',
                    username: '8d4347979a99de7837d65765',
                    credential: 'TZUQCNugWRrgKQwi'
                }
            ],
            stunServers: {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]},
            // signaler: new SignalingChannel(),
            // signalServerURL: "wss://gallery.plackiel.com:3737/ws/",
            // signalServerURL: "wss://127.0.0.1:3737/ws/"+this.activeStoreId(),
            signalServerURL: "wss://192.168.3.16:3737/ws",
            screenShareElement: UserStore.getters.getScreenShareElement,
            localUser: new RTCPeerConnection(this.stunServers),
        }
    },

    methods: {

        async makeRoomID(string) {
            const crypto = require("crypto");
            const secret = "I Love this Secret Password for Encryption";
            return crypto.createHash('md5', secret).update(string).digest('hex');
        },

        async connectWS() {
            this.wsMessage.room = await this.makeRoomID(UserStore.getters.getActiveStore.storeName + "VEECLiStore")

            this.wsConn = new WebSocket(this.signalServerURL+"/"+this.wsMessage.room)

            this.wsConn.onopen = async () => {
                this.wsConnected = true
				this.wsMessage.type = "join"
                await this.sendMessage(this.wsMessage)
			}

            this.wsConn.onmessage = (event) => {
                if (event.data) {

                    if (event.data == "Disconnected from Server") {
                        this.disconnectWS()
                        // this.wsConn.close()
                        // this.localUser.setRemoteDescription(new RTCSessionDescription(this.wsMessage))
                        // this.processMessage(this.wsMessage)
                    } else {
                        this.processMessage(event)
                    }
                } else {
                    console.log("Unexpected message received", event)
                }
            }

        },

        async processMessage(event) {
            console.log("Processing Recieved Event", event)
            if (event.data) {
                var someText = event.data.replace(/(\r\n|\n|\r)/gm, "");
                var msgData = JSON.parse(someText)
                if (msgData.type == "offer") {
                    this.localUser.setRemoteDescription(new RTCSessionDescription(msgData))
                    const answer = await this.localUser.createAnswer();
                    await this.localUser.setLocalDescription(answer);
                    console.log("Answer",answer)
                    this.sendMessage({
                        "type": "answer",
                        "room": this.wsMessage.room,
                        "sdp": this.localUser.localDescription
                    })
                }
            } else {
                console.log("No Data ")
                if (msgData.type == "new-ice-candidate") {
                    
                    try {
                        await this.localUser.addIceCandidate(msgData.data);
                        
                    } catch (e) {
                        console.error('Error adding received ice candidate', e);
                    }
                }                
            }
            
        },

        async disconnectWS() {
            this.wsMessage.type = "disconnect"
            await this.sendMessage(this.wsMessage)
            this.wsMessage.room = null
            this.joinedRoom = null
            this.wsConn = null
            this.wsConnected = false
        },

        async startScreenShare() {
            try {
                const constraints = {
                        video: {
                            cursor: 'always' | 'motion' | 'never',
                            // displaySurface: 'browser'
                            displaySurface: 'application' | 'browser' | 'monitor' | 'window'
                        }
                    }

                this.isScreenSharing = true
                // const localUser =  new RTCPeerConnection(this.stunServers)
                this.localUser.addEventListener('icecandidate', event => {
                    if (event.candidate) {
                        this.sendMessage({
                            "type": "new-ice-candidate",
                            "room": this.wsMessage.room,
                            "sdp": event
                        })
                    }
                });
                navigator.mediaDevices.getDisplayMedia(constraints)
                    .then(stream => {
                        const videoElement = document.querySelector('#sharedUserScreen');
                        videoElement.srcObject = stream;
                        this.localUser.addStream(stream)
                        return this.localUser.createOffer()
                    })
                    .then((offer) => {
                        return this.localUser.setLocalDescription(offer)
                    })
                    .then (() => {
                        this.sendMessage({
                            "type": "offer",
                            "room": this.wsMessage.room,
                            "sdp": this.localUser.localDescription
                        })
                    })
                    .then(() => {
                        console.log('Screen Sharing Started');
                    })
            } catch(error) {
                this.isScreenSharing = false
                console.error('Error opening video/audio device(s).', error);
            }
        },

        async stopScreenShare() {
            try {
                this.shareTracks.forEach((track) => {
                    track.stop();
                });
                const videoElement = document.querySelector('#sharedUserScreen');
                videoElement.srcObject = null;
            } catch(error) {
                this.isScreenSharing = false
                console.error('Error closing video/audio device(s).', error);
            }
            this.isScreenSharing = false
            console.log('Screen Sharing Stopped');
            
        },

        async sendMessage (msg) {
            if (this.wsConn && this.wsConn.readyState !== this.wsConn.OPEN) {
                try {
                    await this.waitForOpenConnection()
                    this.wsConn.send(JSON.stringify(msg))
                } catch (err) { console.error(err) }
            } else {
                if (this.wsConn) {
                    this.wsConn.send(JSON.stringify(msg))
                    console.log("Sent message", msg)
                }
            }
        },

        async waitForOpenConnection() {
            return new Promise((resolve, reject) => {
                const maxNumberOfAttempts = 10
                const intervalTime = 200 //ms

                let currentAttempt = 0
                const interval = setInterval(() => {
                    if (currentAttempt > maxNumberOfAttempts - 1) {
                        clearInterval(interval)
                        reject(new Error('Maximum number of attempts exceeded'))
                    } else if (this.wsConn.readyState === this.wsConn.OPEN) {
                        clearInterval(interval)
                        resolve()
                    }
                    currentAttempt++
                }, intervalTime)
            })
        },


    }


}
</script>